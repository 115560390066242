<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="catalogsections3528"
		tooltipMsg="pimanualcatalog"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'CsectionOrder', ascending: 1 }"
		modelId="CsectionId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #CsectionIsVisible="list">
			<boolean-display :boolValue="list.row.CsectionIsVisible"></boolean-display>
		</template>

		<template #CsectionIsVisibleInMenu="list">
			<boolean-display :boolValue="list.row.CsectionIsVisibleInMenu"></boolean-display>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';

import { tableColumns } from './catalog-section.table-data';

export default {
	name: 'CatalogSectionTable',

	components: {
		TablePage,
		BooleanDisplay,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'CatalogSection',
			columns: tableColumns,
		};
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.catalogSectionDetailName,
				params: { id },
			});
		},
	},
};
</script>