var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "catalogsections3528",
      tooltipMsg: "pimanualcatalog",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "CsectionOrder", ascending: 1 },
      modelId: "CsectionId",
    },
    on: {
      addNewRecord: function ($event) {
        return _vm.redirectToDetail()
      },
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "CsectionIsVisible",
        fn: function (list) {
          return [
            _c("boolean-display", {
              attrs: { boolValue: list.row.CsectionIsVisible },
            }),
          ]
        },
      },
      {
        key: "CsectionIsVisibleInMenu",
        fn: function (list) {
          return [
            _c("boolean-display", {
              attrs: { boolValue: list.row.CsectionIsVisibleInMenu },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }