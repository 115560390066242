export const tableColumns = [
	{
		model: 'CsectionId',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CsectionNameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNameSk',
		i18n: 'urlsk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionNameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNameCz',
		i18n: 'urlcz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionNamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNamePl',
		i18n: 'urlpl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionNameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNameEn',
		i18n: 'urlen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionNameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNameDe',
		i18n: 'urlde',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionNameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionUrlNameHu',
		i18n: 'urlhu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CsectionOrder',
		i18n: 'order',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CsectionIsVisible',
		i18n: 'searchfiltersin',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'CsectionIsVisibleInMenu',
		i18n: 'menuofpage',
		sortable: true,
		filter: 'boolean',
	},
];
